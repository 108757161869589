<template>
    <v-dialog v-model="newFolderDialog" max-width="500">
        <template v-slot:activator="{ on: dialog }">
            <v-btn :disabled="disabled" v-on="{ ...dialog }" e2e-space-create-folder>
                <v-icon left color="secondary">create_new_folder</v-icon>
                Create
            </v-btn>
        </template>
        <v-card>
            <v-card-title>
                <div class="title lighten-2 secondary--text">
                    <v-icon color="secondary" left>create_new_folder</v-icon>
                    <span class="text-uppercase">new folder</span>
                </div>
            </v-card-title>
            <v-divider class="mb-1"></v-divider>
            <v-card-text class="mt-1">
                <span class="font-weight-bold">Folder name</span>
                <v-text-field
                    label="Folder name here"
                    v-model="folderName"
                    e2e-space-folder-input
                    autofocus
                    @keyup.enter="createFolder"
                    outlined></v-text-field>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="secondary" class="mr-1" text @click="newFolderDialog = false" e2e-space-folder-cancel :disabled="creatingFolder">cancel</v-btn>
                <v-btn :disabled="!folderName.length" :loading="creatingFolder" @click="createFolder" text color="primary" e2e-space-folder-confirm>
                    create folder
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { enumsData } from '@/mixins/enums'

export default {
    name: 'CreateFolder',
    mixins: [enumsData],
    data: function () {
        return {
            newFolderDialog: false,
            folderName: '',
            creatingFolder: false
        }
    },
    props: {
        path: String,
        disabled: Boolean
    },
    methods: {
        createFolder() {
            this.creatingFolder = true
            const trimmedFolderName = this.folderName.trim()
            if (trimmedFolderName.length === 0) return
            const apiURL = this.$props.path
            const postBody = {
                short_id: trimmedFolderName,
                long_id: trimmedFolderName
            }
            this.$axios
                .post(apiURL, postBody)
                .then(() => {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: 'Folder created successfully!',
                        snackBarTimeout: 5000,
                        snackBarIcon: 'check_circle'
                    })
                    this.$store.dispatch('snapshotStore/fetchCurrentFiles', { id: this.$route.params.snid, route: this.$route, setFetchingStatus: true })
                })
                .catch(() => {
                    this.$store.dispatch('showSnackBar', {
                        snackBarText: `Failed to create folder`,
                        snackBarTimeout: 10000,
                        snackBarIcon: 'error'
                    })
                })
                .finally(() => {
                    this.folderName = ''
                    this.newFolderDialog = false
                    this.creatingFolder = false
                })
        }
    }
}
</script>
